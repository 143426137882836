// External
import React, { useContext, useEffect, useState } from 'react';
import { CollapsableSection, LoadingPlaceholder, PanelContainer, useStyles2 } from '@grafana/ui';
import { css } from '@emotion/css';
import { GrafanaTheme2 } from '@grafana/data';

// Internal
import { Plugin } from 'utils/utils.types';
import { DashboardRow } from './DashboardRow';
import { fetchPluginManifest, GET_DASHBOARD_URL, GET_FOLDER_URL, switchOrgContext } from 'utils/utils.endpoints';
import { ManagerContext } from '../ManagerContext';
import { ADMIN_ORG_ID } from 'utils/utils.constants';
import { ACTIONS } from 'utils/utils.dbActions';
import { FetchError } from 'utils/utils.errors';
import { BatchButtons } from './Buttons/BatchButtons';
import { testIds } from 'components/testIds';

interface DCProps {
    plugin: Plugin,
    setDashboardError: React.Dispatch<React.SetStateAction<string | undefined>>,
    busy: boolean,
    setBusy: React.Dispatch<React.SetStateAction<boolean>>
}

export function DashboardContainer(props: DCProps) {
    const { plugin, busy, setBusy, setDashboardError } = props;

    const style = useStyles2(getStyles);

    const [sorted, setSorted] = useState<any[]>([]);
    const [sortedLoading, setSortedLoading] = useState<boolean>(true);

    const { dataState } = useContext(ManagerContext);
    const { orgs } = dataState;

    useEffect(() => {
        // Set inital state
        setSortedLoading(true);

        // Get actions and sort dashboards
        sortDashboards()
            .catch(err => setDashboardError(err.message));

        // Will only run on mount
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    /**
     * Consolidate multi-language dashboards with those with the same UID.
     * Get actions depending on state of dashboard.
     */
    const sortDashboards = async () => {
        const pluginManifest = await fetchPluginManifest(plugin.id);
        const locales = pluginManifest.dashboards;

        const pluginDashboards: any = {};

        for (const locale in locales) {
            const org = orgs.find((o) => o.language.BCP47 === locale);
            
            // Ignore language if organisation does not exist
            if (!org) {
                continue;
            }

            await switchOrgContext(org.id);
            const folderUid = await getFolder();

            // For each dashboard in language
            const dashboardActions = await Promise.all(locales[locale]
                .map((dashboard) => getActions(dashboard, folderUid)));
            for (let i = 0; i < dashboardActions.length; i++) {
                const actions = dashboardActions[i];
                const dashboard = locales[locale][i];
                // Existing entry
                if (dashboard.uid in pluginDashboards) {
                    pluginDashboards[dashboard.uid].variants.push({
                        orgId: org.id,
                        orgAlias: org.language.orgAlias,
                        language: locale,
                        name: dashboard.name,
                        path: dashboard.path,
                        actions: actions,
                    });
                }
                // Create new entry
                else {
                    pluginDashboards[dashboard.uid] = {
                        uid: dashboard.uid,
                        variants: [{
                            orgId: org.id,
                            orgAlias: org.orgAlias,
                            language: locale,
                            name: dashboard.name,
                            path: dashboard.path,
                            actions: actions,
                        }],
                        version: dashboard.wsProvisionerVersion
                    }
                }
            }
        }

        // Switch back to Grafana
        await switchOrgContext(ADMIN_ORG_ID);


        // Push to array
        const sortedDashboards: any = [];
        for (const key in pluginDashboards) {
            const pluginDb = pluginDashboards[key];

            let actions: string[] = [];
            for (const idx in pluginDb.variants) {
                actions = actions.concat(pluginDb.variants[idx].actions);
            }

            sortedDashboards.push({
                ...pluginDb,
                actions: [...new Set(actions)]
            });
        }

        setSorted(sortedDashboards);
        setSortedLoading(false);
    }

    /**
     * Attempts to retrieve the plugin folder.
     * If the folder does not exist returns null.
     * @returns the folder uid or null.
     */
    const getFolder = async () => {
        const url = GET_FOLDER_URL(plugin.id);
        const res = await fetch(url);
        if (!res.ok) {
            if (res.status === 404) {
                return null;
            }
            else {
                throw new FetchError(res, url);
            }
        }

        const data = await res.json();
        return data.uid;
    }

    /**
     * Fetches dashboard from Grafana and determine valid actions.
     * @param db Dashboard object.
     * @param folderUid Folder UID.
     * @returns an array of valid actions.
     */
    const getActions = async (db: any, folderUid: string) => {
        const actions: string[] = [];

        const url = GET_DASHBOARD_URL(db.uid);
        const res = await fetch(url);
        if (!res.ok) {
            if (res.status === 404) {
                // Dashboard not found can only install
                return [ACTIONS.Install];
            }
            else {
                throw new FetchError(res, url);
            }
        }

        const data = await res.json();
        if (data.meta.folderUid !== folderUid) {
            // Dashboard found, but not in correct folder can only be installed
            return [ACTIONS.Install];
        }

        const installedVersion = data.dashboard.wsProvisionerVersion;
        if (installedVersion) {
            if (installedVersion < db.wsProvisionerVersion) {
                actions.push(ACTIONS.Upgrade);
            }
            else if (installedVersion > db.wsProvisionerVersion) {
                actions.push(ACTIONS.Downgrade);
            }

            // If installed then next action is uninstall
            actions.push(ACTIONS.Uninstall);
        }
        else {
            // Missing wsProvisionerVersion can only be reinstalled to fix
            return [ACTIONS.Install];
        }

        return actions;
    }

    return (
        <PanelContainer
            data-testid={testIds.appManager.main.db.panel}
            className={style.dashboardContainer}
        >
            {sortedLoading
                ? <LoadingPlaceholder
                    data-testid={testIds.appManager.main.db.loading}
                    text="...Loading Dashboards"
                />
                : (sorted.length > 0
                    ?
                    <div data-testid={testIds.appManager.main.db.container}>
                        <CollapsableSection label={plugin.name} isOpen>
                            {sorted.map((db: any) => <DashboardRow
                                key={db.uid}
                                dashboard={db}
                                sorted={sorted}
                                setSorted={setSorted}
                                setDashboardError={setDashboardError}
                                plugin={plugin}
                                busy={busy}
                                setBusy={setBusy}
                            />)}
                        </CollapsableSection>
                        <BatchButtons
                            sorted={sorted}
                            setSorted={setSorted}
                            plugin={plugin}
                            setDashboardError={setDashboardError}
                            busy={busy}
                            setBusy={setBusy}
                        />
                    </div>
                    :
                    <h5>No dashboards found for currently provisioned languages...</h5>
                )
            }
        </PanelContainer>
    );
}

const getStyles = (theme: GrafanaTheme2) => ({
    dashboardContainer: css`
        margin-bottom: ${theme.spacing(1)};
        padding-left: ${theme.spacing(1.5)};
        padding-top: ${theme.spacing(1)};
        padding-right: ${theme.spacing(1.5)};
        padding-bottom: ${theme.spacing(1)};
    `
});
