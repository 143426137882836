// Internal
import { CREATE_FOLDER_URL, DELETE_FOLDER_URL, GET_DASHBOARD_JSON_URL, GET_DASHBOARD_URL, GET_DB_LIST_URL, IMPORT_DASHBOARD_URL, switchOrgContext } from "./utils.endpoints";
import { FetchError } from "./utils.errors";
import { Plugin } from 'utils/utils.types';

export enum ACTIONS {
    Install = 'Install',
    Upgrade = 'Upgrade',
    Downgrade = 'Downgrade',
    Uninstall = 'Uninstall'
};

/**
 * Fetch the dashboard JSON.
 * @param pluginId the plugin UID
 * @param path the path to the dashboard
 * @returns the dashboard
 */
const fetchDashboard = async (pluginId: string, path: string) => {
    const url = GET_DASHBOARD_JSON_URL(pluginId, path);
    const res = await fetch(url);
    if (!res.ok) {
        throw new FetchError(res, url);
    }

    const data = await res.json();
    return data;
}

/**
 * Creates a folder with the plugin UID as the UID.
 * @param folderName Used for naming the new folder
 * @param plugin the plugin
 * @returns the folder UID
 */
const createFolder = async (folderName: string, plugin: Plugin) => {
    const folderBody = {
        "title": folderName,
        "uid": plugin.id,
        "description": plugin.description
    }

    const res = await fetch(CREATE_FOLDER_URL, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(folderBody)
    });
    if (!res.ok) {
        throw new FetchError(res, CREATE_FOLDER_URL);
    }

    const data = await res.json();
    return data.uid;
}

/**
 * Deletes the folder by UID.
 * @param folderUid the folder UID
 */
const deleteFolder = async (folderUid: string) => {
    const url = DELETE_FOLDER_URL(folderUid);
    const res = await fetch(url, {
        method: 'DELETE'
    });

    if (!res.ok) {
        throw new FetchError(res, url);
    }
}

/**
 * Returns whether or not the folder contains dashboards.
 * @param folderUid
 * @returns boolean value
 */
const containsDashboards = async (folderUid: string) => {
    const res = await fetch(GET_DB_LIST_URL);
    if (!res.ok) { 
        throw new FetchError(res, GET_DB_LIST_URL);
    }

    const data = await res.json();

    return data.some((e: any) => e?.folderUid === folderUid);
}

/**
 * Installs dashboard with overwrite flag.
 * @param db the dashboard
 * @param plugin the plugin
 */
const install = async (db: any, plugin: Plugin) => {
    await switchOrgContext(db.orgId);

    const dashboard = await fetchDashboard(plugin.id, db.path);
    if (dashboard.id) {
        delete dashboard.id;
    }

    const body = {
        dashboard: dashboard,
        folderUid: plugin.id,
        overwrite: true
    };

    const res = await fetch(IMPORT_DASHBOARD_URL, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(body)
    });

    if (!res.ok) {
        // Check if request failed because folder does not exist
        if (res.status === 400) {
            const data = await res.json();
            if (data.message === "folder not found") {
                let folderName = plugin.name;

                if (plugin.overrideLabel[db.orgAlias] != null) {
                    folderName = plugin.overrideLabel[db.orgAlias];
                }

                await createFolder(folderName, plugin);

                // Retry POST
                const res2 = await fetch(IMPORT_DASHBOARD_URL, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(body)
                });

                if (!res2.ok) {
                    throw new FetchError(res, IMPORT_DASHBOARD_URL);
                }
            }

        } else {
            throw new FetchError(res, IMPORT_DASHBOARD_URL);
        }
    }

    // Replace install action with uninstall action
    db.actions = db.actions.filter((a: string) => a !== ACTIONS.Install);
    db.actions.push(ACTIONS.Uninstall);
    return db;
}

/**
 * Uninstalls the dashboard.
 * @param db the dashboard
 * @param plugin the plugin
 */
const uninstall = async (db: any, plugin: Plugin) => {
    await switchOrgContext(db.orgId);
    const url = GET_DASHBOARD_URL(db.uid);
    const res = await fetch(url, {
        method: 'DELETE'
    });

    if (!res.ok) {
        throw new FetchError(res, url);
    }

    // Delete folder if it is empty after uninstall
    const folderEmpty = !(await containsDashboards(plugin.id));
    if (folderEmpty) {
        deleteFolder(plugin.id);
    }

    // Set action to install only
    db.actions = [ACTIONS.Install];
    return db;
}

export const FuncMap: Record<ACTIONS, (db: any, plugin: Plugin) => Promise<any>> = {
    [ACTIONS.Install]: install,
    [ACTIONS.Upgrade]: install,
    [ACTIONS.Downgrade]: install,
    [ACTIONS.Uninstall]: uninstall
};

