// External
import React, { useContext, useEffect, useState } from 'react';
import { Field, LoadingPlaceholder, Select } from '@grafana/ui';

// Internal
import { ManagerContext } from './ManagerContext';
import { ADMIN_ORG_ID, DEFAULT_LANGUAGE, DEFAULT_LANGUAGE_ORG_ALIAS, PLUGIN_ID } from 'utils/utils.constants';
import { FetchError } from 'utils/utils.errors';
import {
    GET_CONFIG_URL,
    GET_INIT_URL,
    GET_LANGUAGES_URL,
    GET_ORGS_URL,
    GET_PLUGINS_URL,
    GET_TEAMS_URL,
    switchOrgContext
} from 'utils/utils.endpoints';
import { EmptyFormState, ParseLanguage, Team, Language } from 'utils/utils.types';
import { testIds } from 'components/testIds';
import { SelectableValue } from '@grafana/data';

type SelectableTeam = Required<Pick<SelectableValue<string>, 'label' | 'value'>>;

export function TeamSelector() {
    const [teams, setTeams] = useState<SelectableTeam[]>([]);
    const [teamsLoading, setTeamsLoading] = useState<boolean>(true);

    const { setFormErr, setDataState, dataState, formState, setFormState } = useContext(ManagerContext);

    useEffect(() => {
        // Set initial state
        setTeamsLoading(true);
        setFormState(EmptyFormState);

        // Setup data state
        fetchAll();

        // Will only run on mount
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleFetch = async (url: string) => {
        const res = await fetch(url);
        if (!res.ok) {
            throw new FetchError(res, url);
        } else {
            return res;
        }
    }

    /**
     * Fetch all datastates and set in context.
     */
    const fetchAll = async () => {
        try {
            const res = await fetch(GET_INIT_URL);

            if (!res.ok) {
                throw new FetchError(res, GET_INIT_URL);
            }

            const isInited = await res.json();

            if (!isInited) {
                return;
            }

            const [languages, plugins, config] = await Promise.all([
                getAvailableLanguages(),
                fetchWSPlugins(),
                fetchConfig()
            ]);

            const orgs = await fetchOrgs(languages, config.primaryOrgId);
            const teams = await fetchTeams(orgs);

            // Set states
            setDataState({
                orgs,
                teams,
                languages,
                plugins,
                config
            });
        } catch (err: any) {
            setFormErr(err.message);
        }
    };

    /**
     * Fetches all teams across all organisations.
     * @param orgs an array of organisations.
     * @returns an array of teams.
     */
    const fetchTeams = async (orgs: any) => {
        const fetchedTeams: Team[] = [];

        for (const idx in orgs) {
            const org = orgs[idx];
            // Switch organisation context
            await switchOrgContext(org.id);

            const data = await (await handleFetch(GET_TEAMS_URL)).json();
            for (const idx in data.teams) {
                const team = data.teams[idx];

                // Check if fetchedTeams already has an entry for this team
                const jdx = fetchedTeams.findIndex(e => e.name === team.name);
                if (jdx > -1) {
                    fetchedTeams[jdx].orgs.push({
                        orgId: org.id,
                        language: org.language,
                        teamId: team.id
                    });
                }
                else {
                    fetchedTeams.push({
                        name: team.name,
                        email: team.email,
                        orgs: [{
                            orgId: org.id,
                            language: org.language,
                            teamId: team.id
                        }]
                    });
                }
            }
        }

        // Switch back to admin context after finishing fetches
        await switchOrgContext(ADMIN_ORG_ID);

        const teamOptions = fetchedTeams.map((team: Team) => {
            return {
                label: team.name,
                value: team.name
            };
        });

        setTeams(teamOptions);
        setTeamsLoading(false);
        return fetchedTeams;
    }

    /**
     * Fetch all organisations and get their languages excluding
     * the Grafana Admin organisation and non provisioned languages.
     *
     * Error is thrown if the primary organisation specified by `primaryOrgId` is not found
     * among the fetched organisations.
     *
     * @param {any} languages - The languages to filter organisations by.
     * @param {number} primaryOrgId - The primary organisation id to search for.
     * @returns {Promise<Array>} A promise that resolves to an array of organisations,
     * @throws {Error} Throws an error if the primary organisation is not found.
     */
    const fetchOrgs = async (languages: any, primaryOrgId: number) => {
        const data = await (await handleFetch(GET_ORGS_URL)).json();
        const nonAdminOrgs = data.filter((org: any) => org.id !== ADMIN_ORG_ID);

        let primaryFound = false;
        const mapOrgs = nonAdminOrgs.map((org: any) => {
            const orgAttr = org.name.split('_');
            // If possible language found
            if (org.id === primaryOrgId) {
                primaryFound = true;
                return {
                    id: org.id,
                    name: org.name,
                    language: {
                        BCP47: DEFAULT_LANGUAGE.BCP47,
                        full: DEFAULT_LANGUAGE.full,
                        orgAlias: DEFAULT_LANGUAGE_ORG_ALIAS,
                    },
                    primary: true
                }
            }
            else if (orgAttr.length > 1) {
                // Validate BCP-47 Language code
                const code = orgAttr[1];
                const matchedLang = languages.find((l: Language) => l.orgAlias === code);

                if (!matchedLang) {
                    return undefined;
                }

                const full = ParseLanguage(matchedLang.BCP47);

                if (full && matchedLang) {
                    return {
                        id: org.id,
                        name: org.name,
                        language: {
                            BCP47: matchedLang.BCP47,
                            full: full,
                            orgAlias: matchedLang.orgAlias,
                        },
                        primary: false
                    };
                }

                // Ignore teams with malformed or invalid languages
                return undefined;
            }
            else {
                return undefined;
            }
        });

        if (primaryFound) {
            return mapOrgs.filter((org: any) => org !== undefined);
        } else {
            throw new Error(`Primary Org with id ${primaryOrgId} was not found.`);
        }
    }

    /**
     * Fetch all WideSky plugins.
     * @returns An array of WideSky plugins
     */
    const fetchWSPlugins = async () => {
        const data = await (await handleFetch(GET_PLUGINS_URL)).json();
        const filtered = data.filter((plugin: any) => {
            const pluginDeps = plugin?.dependencies?.plugins;
            if (pluginDeps.length && pluginDeps.find((dep: any) => dep.id === PLUGIN_ID)) {
                return plugin;
            }
        });

        return filtered.map((plugin: any) => {
            return {
                name: plugin.name,
                id: plugin.id,
                version: plugin.info.version,
                updated: plugin.info.updated,
                author: plugin.info.author.name,
                description: plugin.info.description,
                overrideLabel: plugin.overrideLabel || {}
            };
        });
    };

    /**
     * Get the frontend configurations for the Provisioner
     */
    const fetchConfig = async () => (await handleFetch(GET_CONFIG_URL)).json();

    /**
     * Get the selected team from formState.
     * @returns a select value or undefined
     */
    const getSelectedTeam = () => {
        if (formState.team) {
            return {
                label: formState.team.name,
                value: formState.team.name
            }
        } else {
            return undefined;
        }
    }

    /**
     * Set the selected value into formState.
     * @param value the selected value
     */
    const setSelectedTeam = (value: string) => {
        const team = dataState.teams.find(t => t.name === value);
        if (team) {
            const languages = team.orgs.map(org => org.language);
            setFormState({
                ...formState,
                team: team,
                languages: languages,
                changed: false
            });
        }
    }

    /**
     * Gets all available languages for this instance.
     * @returns array of Language[] types
     */
    const getAvailableLanguages = async () => {
        const data = await (await handleFetch(GET_LANGUAGES_URL)).json();

        return data.languages
            .map((language: any) => {
                const full = ParseLanguage(language.code);
                if (full) {
                    return {
                        BCP47: language.code,
                        full: full,
                        orgAlias: language.orgAlias,
                    };
                } else {
                    return undefined;
                }
            })
            .filter((language: any) => language !== undefined);
    }

    return (
        <>
            {teamsLoading
                ? <LoadingPlaceholder text='...Loading Teams' data-testid={testIds.appManager.main.teamselect.loading}/>
                : <Field label='Team:' data-testid={testIds.appManager.main.teamselect.select}>
                    <Select
                        options={teams.sort((teamA, teamB) => teamA.label.localeCompare(teamB.label))}
                        onChange={(v) => {
                            setSelectedTeam(v.value!);
                        }}
                        value={() => { getSelectedTeam() }}
                    />
                </Field>
            }
        </>
    );
}
